
  import { array, bool, object, string } from 'vue-types'
  import { mapGetters } from 'vuex'
  import moment from 'moment'
  import ThumbnailComponent from '../track/ThumbnailComponent'
  import TitleComponent from '../track/TitleComponent'
  import AuthorComponent from '../track/AuthorComponent'
  import EmailComponent from '../track/EmailComponent'
  import WaveSurferComponent from '../track/WaveSurferComponent'
  import DurationComponent from '../track/DurationComponent'
  import PlaySmallIcon from '../svg/playSmallIcon'
  import PauseSmallIcon from '../svg/pauseSmallIcon'
  import IconsListComponent from '../track/IconsListComponent'
  import audioTimer from '@/mixins/audioTimer'

  export default {
    name: 'TrackComponent',
    components: {
      IconsListComponent,
      PauseSmallIcon,
      PlaySmallIcon,
      DurationComponent,
      WaveSurferComponent,
      AuthorComponent,
      TitleComponent,
      ThumbnailComponent,
      EmailComponent,
    },
    mixins: [audioTimer],
    props: {
      track: object().required,
      isMainPage: bool().def(false),
      isDetailTrack: bool().def(false),
      iconsList: array().def([
        'favorites',
        'same',
        'download',
        'shared',
        'cart',
      ]),
      trackPrefix: string().def(''),
    },
    data() {
      return {
        playing: false,
      }
    },
    computed: {
      ...mapGetters('player', ['isPlay', 'currentTrack', 'volume', 'prefix']),
      ...mapGetters('music', ['filters']),

      authorName() {
        return this.track.author_brand || this.track.author
      },
      authorEmail() {
        return this.track.author_email
      },
      moodsList() {
        const filterMoodsLabel = this.filters.moods_ids?.map((m) => m.label)
        let selectedMoods = []

        if (filterMoodsLabel) {
          selectedMoods = this.track.moods.filter((m) =>
            filterMoodsLabel.includes(m)
          )
        }
        selectedMoods = [...selectedMoods, ...this.track.moods]

        return Array.from(new Set(selectedMoods)).slice(0, 3).sort().join(', ') // this.track.moods.join(", ");
      },
      trackIsNew() {
        return this.track?.date_approved ? moment(this.track.date_approved).isBetween(
          moment().subtract(3, 'days'),
          moment()
        ) : false
      },
      genres() {
        return this.track.genres.join(', ')
      },
      waveName() {
        return `${this.trackPrefix}-${this.track.id}`
      },
    },
    watch: {
      currentTrack(c, p) {
        if (p && c) {
          this.playing = false
          if (window[`waves${this.prefix}-${p.id}`].backend) window[`waves${this.prefix}-${p.id}`].seekAndCenter(0)
          // this.audioTimer()
          // window['waves-0'].unAll()
          // window['waves-0'].destroy()
          // window.hls.destroy()
        }

        if (!c) {
          if (window[`waves${this.prefix}-${p.id}`].backend) window[`waves${this.prefix}-${p.id}`].seekAndCenter(0)
        }
      },
      prefix(c, p) {
        if (p && c && p !== c && this.currentTrack?.id) {
          this.playing = false
          window[`waves${p}-${this.currentTrack.id}`].seekAndCenter(0)
          // window['waves-0'].unAll()
          // window['waves-0'].destroy()
          // window.hls.destroy()
        }
      },

      isPlay(c, p) {
        if (
          this.currentTrack?.id === this.track.id &&
          this.prefix === this.trackPrefix
        ) {
          this.playing = c
        }
      },
    },

    beforeMount() {
      this.playing = this.currentTrack?.id === this.track.id
    },

    mounted() {
      // используется для загрузки и отображения звуковой дорожки в каталоге
      this.$nextTick(() => {
        // eslint-disable-next-line no-undef
        window[`waves${this.waveName}`] = WaveSurfer.create({
          container: `.wavesform${this.waveName}`,
          waveColor: 'rgba(37, 37, 37, .2)',
          progressColor: 'rgba(37, 37, 37, 1)',
          // normalize: true,
          barWidth: 2,
          barHeight: 1, // the height of the wave
          barGap: null,
          height: this.isDetailTrack ? 60 : 25,
          interact: false,
          cursorWidth: 0,
          mediaControls: false,
        })
        window[`waves${this.waveName}`].setVolume(0)

        if (
          typeof this.track.waveform_data === 'string' &&
          this.track.waveform_data.length
        ) {
          window[`waves${this.waveName}`].backend.peaks = JSON.parse(
            this.track.waveform_data
          ).data
        }
        window[`waves${this.waveName}`].drawBuffer()
      })
    },

    beforeDestroy() {
      // this.$store.dispatch('player/setIsPlay', false)
      // if (window['waves-0']) window['waves-0'].un('seek')
      if (this.waveName && window[`waves${this.waveName}`]) {
        window[`waves${this.waveName}`].unAll()
        window[`waves${this.waveName}`].destroy()
      }
    },

    methods: {
      async playPause(playing = !this.playing) {
        if (
          this.track?.id !== this.currentTrack?.id ||
          this.trackPrefix !== this.prefix
        ) {
          await this.$store.dispatch('player/setCurrentTrack', this.track)
          await this.$store.dispatch('player/setPrefix', this.trackPrefix)
          this.audioTimer()
        }
        await this.$store.dispatch('player/setIsPlay', playing)
        this.playing = playing
      },
    },
  }
